<template>
  <div class="reportRelease">
    <div class="nf-tabs">
      <tab-nav :tabsNav="tabsNav" @changeTabsNav="switchNav"></tab-nav>
    </div>
    <div class="info-panel" v-if="currentTabsNav === 0">
      <el-row class="sel-row">
        <el-col class="col_title"><span>检测结果：</span></el-col>
        <el-col  :span="spanWidth">{{list.checkResult || '暂无'}}</el-col>
      </el-row>
      <el-row class="sel-row">
        <el-col class="col_title"><span>附件：</span></el-col>
        <el-col  :span="spanWidth" v-if="list.appendixId == '' || list.appendixId==null">
          暂无
        </el-col>
        <el-col  :span="spanWidth" v-else>
          <div class="word_panel" v-for="item in list.appendixId" :key="item.fileId">
            <i class="icon icon-pdf" v-if="getFileSuffix(item.name) === 'pdf'"></i>
            <i class="icon icon-ppt" v-if="getFileSuffix(item.name) === 'ppt'"></i>
            <i class="icon icon-word" v-if="getFileSuffix(item.name) === 'word'"></i>
            <i class="icon icon-exl" v-if="getFileSuffix(item.name) === 'exl'"></i>
            <i class="icon icon-tp" v-if="getFileSuffix(item.name) === 'img'"></i>
            <span class="wordText">{{item.name}}</span>
            <i class="down-i el-icon-download" @click="downWord(item,item.name,getFileSuffix(item.name))"></i>
          </div>
        </el-col>
      
      </el-row>
    </div>
    <div class="info-panel reust-panel" v-if="currentTabsNav === 1">
      <div v-if="list.checkReportId == '' || list.checkReportId == null ">
        暂无数据
      </div>
      <div v-else>
          
        <el-carousel indicator-position="outside">
          <el-carousel-item v-for="item in list.checkReportId" :key="item.fileId">
            <div class="img-panel">
              <img :src="item.filePath"/>
            </div>
            <!-- <h3>{{ item }}</h3> -->
          </el-carousel-item>
        </el-carousel>
      </div>

    </div>
  </div>
</template>
<script>
import TabNav from './../component/tab-nav';
import { downlodFile, downloadImg} from '@/utils/utils';

export default {
  components: {
    TabNav
  },
  data(){
    return {
      spanWidth: 13,
      isTabsNav: true,
      currentTabsNav: 0,
      tabsNav: ['检测报告', '检测结果'],
      list: []
    };
  },
  props: {
      specID: { type: String }
  },
  created(){
    this.getInfo();
  },
  methods: {
    getFileSuffix(fileName){
      let _endName = fileName.endsWith(".pdf");
      _endName = fileName.endsWith(".pdf") ? 'pdf' : fileName.endsWith(".docx") || fileName.endsWith(".doc") ? 'word' : fileName.endsWith(".xlxs") ? 'exl' : 'img';
      return _endName;
    },
     // 获取发布生产信息
    async getInfo(){
      const api = this.$fetchApi.queryDetail.api + this.specID;
      const _params={ id: this.specID };
      
      const data = await this.$fetchData.fetchGet(_params, api);
      if (data.code === '200'){
        this.list = data.result;
      }
    },
    // 点击下载的时候文件下载
    downWord(data, fileName, endSuffix){
      console.log(endSuffix);
      if (endSuffix === 'img'){
        downloadImg(data.filePath);
      } else {
        //文件下载
        downlodFile(data, fileName);
      }
    },
    switchNav(data) {
      console.log(data);
      this.currentTabsNav = data;
    },
    handClickBtn(val){
      this.isTabsNav = val;
    }
  }
};
</script>
<style lang="less" scoped>
.info-panel{
  padding-top: 30px;
}
.reust-panel{
  width: 60%;
  .img-panel{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.sel-row{
    // line-height: 50px;
    padding: 30px 0;
    font-size: 15px;
    .col_title{
      text-align: right;
      color: #999999;
      width: 160px;
      margin-right: 20px;
    }
    .upText{
      position: absolute;
      bottom: -5px;
      color: #969EB4;
      font-size: 14px;
      text-align: center;
      padding-left: 4px;
    }
    .word_panel{
      display: flex;
      align-items: center;
      color: #4B5162;
      padding: 7px 0;
      .wordText{
        display: inline-block;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 80px 0 0px;
      }
      .icon{
        display: inline-block;
        // vertical-align: middle;
        text-align: center;
        width: 20px;
        height: 20px;
        background: none no-repeat center;
        background-size: 100%;
        margin-right: 4px;
        &-pdf{
          background-image: url("../../../assets/images/laboratoryInfo/icon_pdf.png");
        }
        &-exl{
          background-image: url("../../../assets/images/laboratoryInfo/icon_exl.png");
        }
        &-ppt{
          background-image: url("../../../assets/images/laboratoryInfo/icon_ppt.png");
        }
        &-word{
          background-image: url("../../../assets/images/laboratoryInfo/icon_word.png");
        }
        &-tp{
          background-image: url("../../../assets/images/laboratoryInfo/icon_tp.png");
        }
      }
      .down-i{
        font-size: 18px;
        cursor: pointer;
      }
    }
}
</style>