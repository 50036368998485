getFileSuffix/**
 *报告生成
 */
<template>
  <div class="reportGeneration">
    <el-row class="sel-row">
      <el-col class="col_title"><span>检测结果：</span></el-col>
      <el-col  :span="spanWidth">{{list.checkResult}}</el-col>
    </el-row>
    <el-row class="sel-row">
      <el-col class="col_title"><span>附件：</span></el-col>
      <el-col  :span="spanWidth" v-if="list.appendixId == '' || list.appendixId==null">
        暂无
      </el-col>
      <el-col  :span="spanWidth" v-else>
        <div class="word_panel" v-for="item in list.appendixId" :key="item.fileId">
          <i class="icon icon-pdf" v-if="getFileSuffix(item.name) === 'pdf'"></i>
          <i class="icon icon-ppt" v-if="getFileSuffix(item.name) === 'ppt'"></i>
          <i class="icon icon-word" v-if="getFileSuffix(item.name) === 'word'"></i>
          <i class="icon icon-exl" v-if="getFileSuffix(item.name) === 'exl'"></i>
          <i class="icon icon-tp" v-if="getFileSuffix(item.name) === 'img'"></i>
          <span class="wordText">{{item.name}}</span>
          <i class="down-i el-icon-download" @click="downWord(item,item.name,getFileSuffix(item.name))"></i>
        </div>
      </el-col>
      
    </el-row>
    <el-row class="sel-row">
      <el-col class="col_title"><span>上传报告：</span></el-col>
      <el-col  :span="spanWidth">
        <uploadImgSample
              :moduleList="moduleList"
              ref="imgUrl"
              @changeImgList="changeImgUrl"
              :fileData="img"
            ></uploadImgSample>
        <p class="upText">图片尺寸不能大于2M</p>
      </el-col>
    </el-row>
    <el-row class="protBtn">
      <div class="sub-btn" @click="submit()">提交</div>
    </el-row>
  </div>
</template>
<script>
import uploadImgSample from "@/component/uploadImgSample/index.vue";
import { downlodFile, downloadImg} from '@/utils/utils';
import { message } from 'element-ui';


export default {
   components: {
      uploadImgSample
  },
  data(){
    return {
      spanWidth: 13,
      moduleList: {
        module: "nanfanRecords",
        subModule: "personRecord"
      },
      img: '',
      list: [],
      params: {
        checkReportId: [],
        id: this.specID
      }
    };
  },
  props: {
      specID: { type: String }
  },
  created(){
    this.getInfo();
  },
  methods: {
    getFileSuffix(fileName){
      let _endName = fileName.endsWith(".pdf");
      _endName = fileName.endsWith(".pdf") ? 'pdf' : fileName.endsWith(".docx") || fileName.endsWith(".doc") ? 'word' : fileName.endsWith(".xlxs") ? 'exl' : 'img';
      return _endName;
    },
    // 获取发布生产信息
    async getInfo(){
      const api = this.$fetchApi.queryDetail.api + this.specID;
      const _params={ id: this.specID };
      
      const data = await this.$fetchData.fetchGet(_params, api);
      if (data.code === '200'){
        this.list = data.result;
      }
    },
    // 点击下载的时候文件下载
    downWord(data, fileName, endSuffix){
      if (endSuffix === 'img'){
        downloadImg(data.filePath);
      } else {
        //文件下载
        downlodFile(data, fileName);
      }
    },
    // 获取图片
    getImgList(fileList) {
      const files = [];
      if (fileList.length > 0) {
        fileList.forEach(item => {
          files.push({
            filePath: item.filePath,
            fileId: item.id
          });
        });
      }
      return files;
    },
    // 上传图片
    changeImgUrl(fileList) {
      this.params.checkReportId = [];
      this.img = this.getImgList(fileList);
      console.log(fileList);
      fileList.forEach(item => {
        this.params.checkReportId.push(item.id);
      });
      // this.params.checkReportId.push(fileList[0].id);
    },
    async submit(){
      if (this.params.checkReportId == ''){
        this.$message.error('请上传报告！');
        return;
      }
      const api = this.$fetchApi.exportSample.api;
      
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      if (data.code === '200'){
        this.$emit('handMeth');
      }
    }
  }
};
</script>
<style lang="less" scoped>
.sel-row{
    // line-height: 50px;
    padding: 20px 0;
    font-size: 15px;
    .col_title{
      text-align: right;
      color: #999999;
      width: 160px;
      margin-right: 20px;
    }
    .upText{
      position: absolute;
      bottom: -5px;
      color: #969EB4;
      font-size: 14px;
      text-align: center;
      padding-left: 4px;
    }
    .word_panel{
      display: flex;
      align-items: center;
      color: #4B5162;
      padding: 7px 0;
      .wordText{
        display: inline-block;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 80px 0 0px;
      }
      .icon{
        display: inline-block;
        // vertical-align: middle;
        text-align: center;
        width: 20px;
        height: 20px;
        background: none no-repeat center;
        background-size: 100%;
        margin-right: 4px;
        &-pdf{
          background-image: url("../../../assets/images/laboratoryInfo/icon_pdf.png");
        }
        &-exl{
          background-image: url("../../../assets/images/laboratoryInfo/icon_exl.png");
        }
        &-ppt{
          background-image: url("../../../assets/images/laboratoryInfo/icon_ppt.png");
        }
        &-word{
          background-image: url("../../../assets/images/laboratoryInfo/icon_word.png");
        }
        &-tp{
          background-image: url("../../../assets/images/laboratoryInfo/icon_tp.png");
        }
      }
      .down-i{
        font-size: 18px;
        cursor: pointer;
      }
    }
}
.protBtn{
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 60px;
    padding-left: 100px;
    .sub-btn{
      width: 150px;
      line-height: 35px;
      color: #ffffff;
      background-color:#3387FD;
      border-radius: 5px;
      text-align: center;
    }
    /deep/ .el-form-item__content{
      margin-left: 20px!important;
    }
  }
</style>