<template>
  <div class="getDialog" v-if="isVisible">
    <div class="closeBtn"  @click="handleClose"><i class="el-icon-arrow-left"></i></div>
    <!-- 头部标题 -->
    <div class="title-panel" v-if="isTitle">
      <i class="tlt-icon"></i>
      <span>{{title}}</span>
    </div>
    <!-- 查看样品 -->
    <div class="selFrom" v-if="conType=='select'">
      <el-row class="sel-row">
        <el-col class="col_title"><span>样品编号：</span></el-col>
        <el-col :span="spanWidth">{{specList.specimenCode}}</el-col>
      </el-row>
      <el-row class="sel-row">
        <el-col class="col_title"><span>样品名称：</span></el-col>
        <el-col :span="spanWidth">{{specList.specimenName}}</el-col>
      </el-row>
      <el-row class="sel-row">
        <el-col class="col_title"><span>样品信息：</span></el-col>
        <el-col :span="spanWidth">{{specList.specimenMessage}}</el-col>
      </el-row>
      <el-row class="sel-row">
        <el-col class="col_title"><span>送样组织信息：</span></el-col>
        <el-col :span="spanWidth">{{specList.specimenUnit}}</el-col>
      </el-row>
      <el-row class="sel-row">
        <el-col class="col_title"><span>检测要求：</span></el-col>
        <el-col :span="spanWidth">{{specList.checkRequire}}</el-col>
      </el-row>
      <el-row class="sel-row">
        <el-col class="col_title"><span>出报告日期：</span></el-col>
        <el-col :span="spanWidth">{{specList.reportDate}}</el-col>
      </el-row>
      <el-row class="sel-row">
        <el-col class="col_title"><span>报价：</span></el-col>
        <el-col :span="spanWidth">{{specList.quotedPrice}}</el-col>
      </el-row>

      <el-row class="btn">
        <div @click="handDown" class="downloadBtn"><i class="el-icon-download"></i><span>下载</span></div>
        <div class="downloadBtn" @click="creatCode"><i class="el-icon-code"></i><span>生成条码</span></div>
        <!-- <el-button class="sel-btn" type="primary" @click="submitDialog()">生成条码</el-button> -->
      </el-row>
    </div>
    <!-- 添加样品 -->
    <div class="addFrom" v-if="conType=='add'">
      <el-form label-position="right" label-width="130px" ref='formAdd' :rules="addRules" :model="formAdd">
        <el-form-item label="样品编号：" prop="specimenCode">
          <el-input class="inputText0" :disabled="true" v-model="formAdd.specimenCode" placeholder="请输入样品编号"></el-input>
        </el-form-item>
        <el-form-item label="样品名称：" prop="specimenName">
          <el-input class="inputText" v-model="formAdd.specimenName" placeholder="请输入样品名称" maxlength="50"></el-input>
          <span class="tipText">最多50个字</span>
        </el-form-item>
        <el-form-item label="样品信息：" prop="specimenMessage">
          <el-input 
            class="inputTextarea" 
            type="textarea" 
            v-model="formAdd.specimenMessage" 
            placeholder="请输入样品信息" 
            maxlength="100"
            show-word-limit
            :autosize="{ minRows: 2, maxRows: 4}"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="送样组织信息：" prop="specimenUnit">
          <el-input class="inputText" v-model="formAdd.specimenUnit" placeholder="请输入送样组织信息"></el-input>
          <span class="tipText">最多50个字</span>
        </el-form-item>
        <el-form-item label="检测要求：" prop="checkRequire">
          <el-input 
            class="inputText" 
            type="textarea" 
            v-model="formAdd.checkRequire" 
            placeholder="请输入检测要求"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item label="出报告日期：" prop="reportDate">
          <el-date-picker 
            class="inputText0"
            v-model="formAdd.reportDate"
            :picker-options='pickerOptions' 
            type="date" 
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="报价：" prop="quotedPrice">
          <el-input class="inputText0" v-model="formAdd.quotedPrice" placeholder="请输入报价"></el-input>
        </el-form-item>
        
        <el-form-item class="protBtn">
          <div class="sub-btn" @click="submitForm('formAdd')">提交</div>
        </el-form-item>
      </el-form>
    </div>

    <!-- 申请复核 -->
    <div v-if="conType=='sqfh'">
      <applyForReview :specID="specID" @handMeth="handleClose"></applyForReview>
    </div>
      
    <!-- 报告生成 -->
    <div v-if="conType=='bgsc'">
      <reportGeneration :specID="specID" @handMeth="handleClose"></reportGeneration>
    </div>

    <div v-if="conType=='bgfb'">
      <reportRelease :specID="specID" @handMeth="handleClose"></reportRelease>
    </div>

    <el-dialog :visible.sync="codeVisble" width="17.5%">
      <div id="qrcode"></div>
    </el-dialog>
  </div>
</template>
<script>
import applyForReview from '@/pages/laboratoryInfo/sampleChild/applyForReview.vue';
import reportGeneration from '@/pages/laboratoryInfo/sampleChild/reportGeneration.vue';
import reportRelease from '@/pages/laboratoryInfo/sampleChild/reportRelease.vue';
import { formateDate, downlodFile } from '@/utils/utils';
import QRCode  from "qrcodejs2";
import config from '@/utils/config';

export default {
  components: {
    applyForReview,
    reportGeneration,
    reportRelease,
    QRCode
  },
  data(){
    return {
      spanWidth: 13,
      isVisible: this.dialogVisible,
      specList: {
        specimenCode: '',
        specimenName: '',
        specimenMessage: '',
        specimenUnit: '',
        checkRequire: '',
        reportDate: '',
        quotedPrice: ''
      },
      formAdd: {
        specimenCode: '',
        specimenName: '',
        specimenMessage: '',
        specimenUnit: '',
        checkRequire: '',
        reportDate: '',
        quotedPrice: ''
      }, // 样品添加集
      pickerOptions: {// 日期配置
        disabledDate(time) {
          // 设置选择今天以及今天之后的日期
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      addRules: {// 添加样品 规则验证
        specimenName: [{ required: true, message: '请输入样品名称', trigger: 'blur' }],
        specimenMessage: [{ required: true, message: '请输入样品信息', trigger: 'blur' }],
        specimenUnit: [{ required: true, message: '请输入送样组织信息', trigger: 'blur' }],
        checkRequire: [{ required: true, message: '请输入检测要求', trigger: 'blur' }],
        reportDate: [{ required: true, message: '请选择选择日期', trigger: 'blur' }],
        quotedPrice: [{ required: true, message: '请输入报价', trigger: 'blur' }]
      },
      codeVisble: false,
      codeUrl: location.origin
    };
  },
  props: {
      title: { type: String, default: '提示' },
      dialogVisible: { type: Boolean },
      conType: { type: String, default: 'select' },
      specID: { type: String },
      isTitle: { type: Boolean, default: true }
  },
  created(){
    if (this.conType === 'select'){
      this.getSpecimenInfo(this.specID);
    } else if (this.conType === 'add'){
      this.formAdd.specimenCode = this.createSpecimenCode();
    }
    // console.log(this.rowInfo);
  },
  methods: {
    //  生成二维码
    qrcode () {
        const that = this;
        this.codeUrl = location.origin + '/nanfan-portal/sampleManageInfo.html?specimenCode=' + encodeURIComponent(this.specList.specimenCode) + 
                      '&specimenName=' + encodeURIComponent(this.specList.specimenName) + '&specimenMessage=' + encodeURIComponent(this.specList.specimenMessage) +
                      '&specimenUnit=' + encodeURIComponent(this.specList.specimenUnit) + '&checkRequire=' + encodeURIComponent(this.specList.checkRequire) +
                      '&reportDate=' + encodeURIComponent(this.specList.reportDate) + '&quotedPrice=' + encodeURIComponent(this.specList.quotedPrice);
        console.log(this.codeUrl);
        const qrcode = new QRCode('qrcode', {
            width: 200,
            height: 200,        // 高度
            text: this.codeUrl   // 二维码内容
            // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            // background: '#f0f',   // 背景色
            // foreground: '#ff0'    // 前景色
        });
    },
    creatCode(){
      this.codeVisble = true;
      setTimeout(() => {
        document.getElementById('qrcode').innerHTML = '';
        this.$nextTick (function () {
          this.qrcode();
        });
      }, 50);
      
    },
    // 查看样品信息
    async getSpecimenInfo(id){
      const api = this.$fetchApi.queryDetail.api + id;
      const _params={ id };
      
      const data = await this.$fetchData.fetchGet(_params, api);
      if (data.code === '200'){
        this.specList = data.result;
      }
    },
    // 下载样品信息word
    async handDown(){
      const api = this.$fetchApi.downloadWord.api + this.specID;
      const _params={ id: this.specID };
      
      const data = await this.$fetchData.fetchGet(_params, api);
      downlodFile(data, this.specList.specimenCode + '.doc');
    },
    //生成样品编号
    createSpecimenCode(){
      return 'SM' + Date.now();
    },
    //关闭弹框
    handleClose () {
      this.isVisible = false;
      this.$emit('handClose', this.isVisible);
    },
    submitDialog(){
      this.isVisible = false;
      this.$emit('handClose', this.isVisible);
    },
    //提交新增样品
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid){
          this.insertSample(formName);
        }
      });
    },
    //新增样品
    async insertSample(formName){
      const api = this.$fetchApi.insertSample.api;
      this.formAdd.reportDate = formateDate(this.formAdd.reportDate);
      const data = await this.$fetchData.fetchPost(this.formAdd, api, 'json');
      if (data.code === '200'){
        this.handleClose();
        this.resetForm(formName);
      }
    },
    //重置新增样品输入框
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    dialogVisible(val){
      this.isVisible = val;
    }
  }
};
</script>
<style lang="less" scoped>
.getDialog{
  padding: 20px;
  width: 100%;
  position: relative;
  .closeBtn{
    position: absolute;
    top: 5px;
    left: 0px;
    // right: 35%;
    font-size: 22px;
    color: #999999;
    cursor: pointer;
    &:hover{
      color: #3387FD;
    }
  }
 .selFrom{
   width: 100%;
  .sel-row{
    line-height: 50px;
    font-size: 15px;
    .col_title{
      text-align: right;
      color: #999999;
      width: 160px;
      margin-right: 20px;
    }
  }
  .btn{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    .downloadBtn{
      color: #519eff;
      // padding: 12px 60px;
      text-align: center;
      line-height: 35px;
      width: 140px;
      border: 1px solid #3387FD;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      margin-right: 40px;
      &:hover{
        background-color: #3387FD;
        color: #ffffff;
      }
      .el-icon-code{
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 12px;
        height: 12px;
        background: none no-repeat center;
        background-size: 100%;
        margin-right: 4px;
        background-image: url("../../../assets/images/laboratoryInfo/icon_code.png");
      }
    }
    
  }
 }

  .addFrom{
    margin-top: 20px;
    
    .tipText{
      color: #959DB3;
      font-size: 12px;
      margin-left: 10px;
    }
    .inputText0{
      width: 200px;
    }
    .inputText{
      width: 400px;
    }
    .inputTextarea{
      width: 400px;
      height: 75px;
    }
    .protBtn{
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 40px;
      .sub-btn{
        width: 150px;
        line-height: 35px;
        color: #ffffff;
        background-color:#3387FD;
        border-radius: 5px;
        text-align: center;
      }
      /deep/ .el-form-item__content{
        margin-left: 20px!important;
      }
    }
  }

  .title-panel{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    line-height: 30px;
    font-size: 17px;
    .tlt-icon{
      width: 10px;
      height: 10px;
      background-color: #4380fe;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }
  }

  /deep/ .el-textarea__inner{
    height: 75px!important;
  }
  /deep/ .el-input__count{
    bottom: -8px;
    background: transparent;
  }
}

</style>