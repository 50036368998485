<template>
  <div class="container">
    <!-- <div class="nf-tabs">
      <tab-nav :tabsNav="tabsNav" @changeTabsNav="switchNav"></tab-nav>
    </div> -->
    <div class="nf-tabs-content" v-if="isShowMain">
      <div class="nf-tabs-cont">
        <div class="header-text">实验室信息管理系统</div>
        <div class="operate">
          <el-button type="primary" @click="handAdd" icon="el-icon-document-add">样品登录</el-button>
        </div>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column align="center" prop="specimenCode" label="样品编号" width="160"></el-table-column>
          <el-table-column align="center" prop="specimenName" label="样品名称"></el-table-column>
          <el-table-column align="center" prop="specimenUnit" label="送样单位"></el-table-column>
          <el-table-column align="center" prop="specimenDate" label="收样日期">
            <template slot-scope="scope">
              <span>{{formToDate(scope.row.specimenDate)}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="specimenUserName" label="收样人员">
            <template slot-scope="scope">
              <span>{{scope.row.specimenUserName == null ? '-' : scope.row.specimenUserName}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="reportDate" label="出报告日期"></el-table-column>
          <el-table-column align="center" prop="status" label="状态">
            <template slot-scope="scope">
              <span :class="['statusText',
                              {'statusText0': scope.row.status === 1},
                              {'statusText1': scope.row.status === 2},
                              {'statusText2': scope.row.status === 3}]"
              >
                {{scope.row.status==1?'待分配':scope.row.status==2?'检测中':scope.row.status==3?'复核中':'已办结'}}
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width = "200">
            <template slot-scope="scope">
              <el-button @click="handEdit(scope.row,'select','样品信息-' + scope.row.specimenCode)" type="text" size="small">查看</el-button>
              <el-button @click="apportionEdit(scope.row)" v-if="scope.row.status === 1 && !scope.row.isDisable" type="text" size="small">分配</el-button>
              <el-button @click="handEdit(scope.row,'sqfh','申请复核')" v-if="scope.row.status === 2 && !scope.row.isDisable" type="text" size="small">申请复核</el-button>
              <el-button @click="handEdit(scope.row,'bgsc','报告生成')" v-if="scope.row.status === 3 && !scope.row.isDisable" type="text" size="small">报告生成</el-button>
              <el-button @click="handEdit(scope.row,'bgfb','报告发布')" v-if="scope.row.status === 4" type="text" size="small">报告发布</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <pagination
        v-if="page.total > param.pageSize"
        @pageChange="pageChange"
        :total="page.total"
        :currentPage="page.currentPage"
        :pageSize="page.pageSize"
      ></pagination>
    </div>

    <!-- 样品管理操作内页 -->
    <sampleDialog v-else 
      :title="selDialogTitle" 
      :dialogVisible="selDialogVisble" 
      :conType="conType" 
      @handClose="closeDialog"
      :specID="specID"
      :isTitle = "isTitle"
    ></sampleDialog>

    <el-dialog title="" :visible.sync="apportionVisible" width="25%" :close-on-click-modal="false">
      <el-row class="aprText">
        <span>是否直接分配检测人员？</span>
      </el-row>
      <el-row class="btn-box">
        <div class="aprt-btn on-btn" @click="handClickPtn">确定</div>
        <div class="aprt-btn no-btn" @click="apportionVisible = false">稍后分配</div>
      </el-row>
    </el-dialog>

    <el-dialog title="" :visible.sync="apportionVisible2" width="25%" :close-on-click-modal="false">
      <el-row class="aprText">
        <span>检测人员分配</span>
      </el-row>
      <el-row class="row-input">
        <el-autocomplete
          width="100%"
          popper-class="my-autocomplete2"
          v-model="keywords"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect">
          <i
            class="el-icon-edit el-input__icon"
            slot="suffix"
            @click="handleIconClick">
          </i>
          <template slot-scope="{ item }">
            <div class="name">{{ item.organizationName }}</div>
            <!-- <span class="addr">{{ item.address }}</span> -->
          </template>
        </el-autocomplete>
      </el-row>
      <el-row class="btn-box">
        <div class="aprt-btn on-btn" @click="handApportion">确定</div>
        <div class="aprt-btn no-btn" @click="apportionVisible2 = false">取消</div>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/component/pagination/index.vue';
import sampleDialog from '@/pages/laboratoryInfo/sampleChild/sampleDialog.vue';
import { formateDate } from '@/utils/utils';


export default {
  components: {
    pagination,
    sampleDialog
  },
  created() {
    this.queryReceiptList();
  },
  data() {
    return {
      id: '',
      isShowMain: true, //主内容
      selDialogTitle: "查看", //查看弹框标题
      selDialogVisble: false, //查看弹框是否显示
      isTitle: true,
      conType: 'select',
      specID: '',
      tabsNav: ['待办'],
      currentTabsNav: 0,
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 8
      },
      param: {
        pageNum: 1,
        pageSize: 8
      },
      tableData: [],
      apportionVisible: false, // 是否显示分配框
      apportionVisible2: false,
      restaurants: [],
      keywords: '',
      memberList: [],
      memberParams: {
        pageNum: 0,
        pageSize: 0,
        searchValue: ''
      },
      checkUserId: '' // 检测员ID
    };
  },
  mounted(){
    this.getSampledMemberList();
  },
  methods: {
    // 分配确定按钮
    handClickPtn(){
      this.apportionVisible = false;
      setTimeout(() => {
        this.apportionVisible2 = true;
        this.restaurants = this.loadAll();
      }, 400);
    },
    formToDate(val){
      return formateDate(val);
    },
    //关闭弹框初始化值
    closeDialog(value){
      this.selDialogVisble = value;
      this.isShowMain=true;
      this.queryReceiptList();
    },
    /**样品新增*/
    handAdd(){
      this.isShowMain = false;
      this.selDialogVisble = true;
      this.conType = 'add';
      this.selDialogTitle = '样品登录-新增';
    },
    /**表格操作 */
    handEdit(data, conType, title){
      this.isShowMain = false;
      this.selDialogVisble = true;
      this.conType = conType;
      this.selDialogTitle = title;
      this.specID = data.id;
      this.isTitle = true;
      if (conType === 'bgfb'){
        this.isTitle = false;
        localStorage.setItem("currentNav", 0);
      }
    },
    /**分配框 */
    apportionEdit(data){
      this.id = data.id;
      this.apportionVisible = true;
    },
    async handApportion(){
      const api = this.$fetchApi.checkSample.api;
      const _params = {
        id: this.id,
        checkUser: this.checkUserId
      };
      const data = await this.$fetchData.fetchPost(_params, api, 'json');
      if (data.code === '200'){
        this.resultCheck();
      }
    },
    async queryReceiptList() {
      const api = this.$fetchApi.queryMyList.api;
      const data = await this.$fetchData.fetchGet(this.param, api, 'json');
      if (data.code === '200'&& data.result) {
        this.tableData = data.result.rows;
        this.page.total = data.result.total;
        // debugger
      }
    },
    pageChange(item) {
      // console.log(item);
      this.param.pageNum = item.page_index;
      this.param.pageSize = item.page_limit;
      this.queryReceiptList(); //改变页码，重新渲染页面
    },
    switchNav(data) {
      this.currentTabsNav = data;
    },
    querySearch(queryString, cb) {
      const restaurants = this.restaurants;
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        // console.log(restaurant);
        if (restaurant.realName == ''||restaurant.realName == null) return;
        return (restaurant.realName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadAll() {
      return this.memberList;
    },
    handleSelect(item) {
      this.keywords = item.realName;
      this.memberParams.searchValue = item.realName;
      this.checkUserId = item.userId;
      console.log(item);
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    // 获取人员列表
    async getSampledMemberList(){
      const api = this.$fetchApi.getSampledMemberList.api;
      const data = await this.$fetchData.fetchPost(this.memberParams, api, 'json');
      if (data.code === '200'&& data.result) {
        this.memberList = data.result.list;
      }
    },
    resultCheck(){
      this.checkUserId = '';
      this.id = '';
      this.apportionVisible2 = false;
      this.queryReceiptList();
    }
  }
};
</script>

<style lang="less" scoped>

.container{
  position: relative;
  .header-text{
    padding: 20px 30px 10px 60px;
  }
  .aprText{
    text-align: center;
    font-size:20px;
    font-weight: bold;
  }
  .row-input{
    margin-top: 20px;
  }
  .btnfb{
    color: #969EB4;
  }
  .btn-box{
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 35px;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
    .aprt-btn{
      width: 120px;
      line-height: 35px;
      border: 1px solid #3380FE;
      border-radius: 5px;
    }
    .on-btn{
      background-color: #3380FE;
    }
    .no-btn{
      border: 1px solid #BFBFBF;
      color: #BFBFBF;
    }
  }
}
.tab-content {
  max-width: 1200px;
  margin: 0 auto;
}
.operate{
  margin: 20px;
}
.statusText{
  color: #666666;
}
.statusText0{
  color: #41B773;
}
.statusText1{
  color: #FD8F35;
}
.statusText2{
  color: #F13D2F;
}
.breadcrumb {
  border: none;
  margin-bottom: 30px;
}

.form-row {
  width: 105px;

  &-date {
    width: 250px;
  }
}

.filter-form {
  padding: 25px 0 0 20px;
}

.el-form-item {
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}

.nf-tag {
  display: inline-block;
  padding: 4px 8px;
  line-height: 11px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 10px;

  &-jinji {
    background: #f56c6c;
  }

  &-ji {
    background: #e6a23c;
  }

  &-yiban {
    background: #909399;
  }
}

.table-cont-title {
  margin-left: 10px;
  cursor: pointer;
}

/deep/ .el-autocomplete{
  width: 100%;
}
</style>
<style>
.my-autocomplete2{
  z-index: 3000!important;
}
</style>