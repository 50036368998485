<template>
  <div class="flex">
    <el-upload
      class="avatar-uploader"
      name="files"
      :action="importFileUrl"
      :headers="header"
      :data="param"
      :on-success="handleAvatarSuccess"
      :on-exceed="handleEaceed"
      :before-upload="beforeAvatarUpload"
      :on-remove="handleRemove"
      :on-error="handleError"
      :limit="limit"
      :file-list="fileList"
      :on-preview="handlePictureCardPreview"
      accept=".doc, .docx, .xls, .xlsx, .pdf, image/jpg, image/jpeg, image/png, image/PNG"
    >
      <el-button size="small" type="primary">上传</el-button>
      <div slot="tip" class="el-upload__tip tipText">可上传多个附件，格式为：word、PDF、excel、图片最多上传6个附件。</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { Message, Dialog } from "element-ui";
import config from '@/utils/config';
import configUtils from '@/utils/config';
// const getToken = window.localStorage.getItem(configUtils.baseToken);
// const token = 'bearer ' + getToken;

Vue.use(Dialog);
export default {
  props: ["moduleList", "fileData", "limits"],
  data() {
    return {
      header: { headers: "multipart/form-data" },
      // importFileUrl: `${config.baseUrl}/api/file/open/upload-file`,
      importFileUrl: `https://open.kechuang.cn/api/filecenter/open/files`,
      imageUrl: [],
      limit: this.limits ? this.limits : 5,
      param: {
        appId:'1585124289720',
        module: this.moduleList.module,
        // subModule: this.moduleList.subModule
        service: this.moduleList.subModule
      },
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: []
    };
  },
  methods: {
    // 上传图片
    handleAvatarSuccess(res, file, fileList) {
      console.log(fileList);
      const files = [];
      if (fileList.length > 0) {
        fileList.forEach((item, index) => {
          if (item.response.code === 0) {
            files.push(item.response.data[0]);
          }
        });
      }
      this.$emit("changeImgList", files);
    },
    // 上传图片已达到上限
    handleEaceed() {
      Message({
        type: "error",
        message: "上传图片已达到上限"
      });
    },
    handleError(err, file, fileList){
      console.log(err, file, fileList,'上传失败log');
    },
    handlePictureCardPreview(file, fileList) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //   删除图片
    handleRemove(file, fileList) {
      const files = [];
      if (fileList.length > 0) {
        fileList.forEach((item, index) => {
          if (item.response.code === "200") {
            files.push(item.response.result[0]);
          }
        });
      }
      this.$emit("changeImgList", files);
    },
    //   上传图片之前
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        Message({
          type: "error",
          message: "上传图片大小不能超过 2MB!"
        });
      }
      return isLt2M;
    }
  }
};
</script>
<style lang='less' scoped>
/deep/.el-upload-list{
  overflow: hidden;
}
/deep/.el-upload-list__item{
  padding: 7px;
  background: #edf0f5;
  width: 28%;
  float: left;
  margin-right: 2%;
  margin-top: 10px;
}
/deep/.el-upload-list__item .el-icon-close{
  top: 12px;
  right: 12px;
}
/deep/.el-upload-list__item-status-label{
  top: 7px;
  right: 12px;
}
.tipText{
  color: #959DB3;
  font-size: 12px;
  margin-left: 10px;
  line-height: 18px;
}
</style>

