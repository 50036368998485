/**
 *申请复核
 */
<template>
  <div class="applyForReview">
    <el-form label-position="right" label-width="130px" ref='formName' :rules="formRules" :model="params">
      <el-form-item label="检查结果：" prop="checkResult">
        <el-input 
          class="inputTextarea" 
          type="textarea" 
          v-model="params.checkResult" 
          placeholder="请输入检查结果" 
          maxlength="100"
          show-word-limit
          :autosize="{ minRows: 2, maxRows: 4}"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="上传附件：" prop="appendixId">
        <uploadMutImg :moduleList='moduleList' :limits="6" @changeImgList='changeImgList'></uploadMutImg>
      </el-form-item>
      <el-form-item label="提交复核人：" prop="reviewUser">
        <el-autocomplete
          class="inputText"
          popper-class="my-autocomplete"
          v-model="params.reviewUserName"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          @select="handleSelect"
          >
          <i
            class="el-icon-edit el-input__icon"
            slot="suffix"
            @click="handleIconClick">
          </i>
          <template slot-scope="{ item }">
            <div class="name">{{ item.organizationName }}</div>
          </template>
        </el-autocomplete>
      </el-form-item>
      
      <el-form-item class="protBtn">
        <div class="sub-btn" @click="submitReview">提交</div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import uploadMutImg from '@/component/uploadFile/index.vue';

export default {
  components: {
    uploadMutImg
  },
  props: {
    specID: { type: String }
  },
  data() {
    return {
      spanWidth: 13,
      moduleList: {
        module: 'dataShare',
        subModule: 'instrumentShare'
      },
      params: {
        id: this.specID, // 样品管理id
        checkResult: '', // 检查结果
        appendixId: [], // 附件id
        reviewUser: '', // 复核人id
        reviewUserName: ''
      },
      formRules: {// 规则验证
        checkResult: [{ required: true, message: '请输入检查结果', trigger: 'blur' }],
        appendixId: [{ required: true, message: '请上传附件', trigger: 'blur' }],
        reviewUser: [{ required: true, message: '请选择复核人' }]
      },
      memberList: [],
      memberParams: {
        pageNum: 0,
        pageSize: 0,
        searchValue: ''
      }
    };
  },
  mounted(){
    this.getSampledMemberList();
  },
  methods: {
    submitReview() {
      this.$refs['formName'].validate(async (valid) => {
        if (valid){
          const data = await this.$fetchData.fetchPost(this.params, '/api/specimen/manage/review', 'json');
          if (data.code === '200') {
            this.$message({ type: 'success', message: data.message });
            this.$emit('handMeth');
          } else {
            this.$message({ type: 'error', message: data.message });
          }
        }
      });
    },
    /** 文件上传 */
    changeImgList(fileList) {
      const files = [];
      if (fileList.length > 0) {
        fileList.forEach(item => {
          files.push(item.id);
        });
      }
      this.params.appendixId = files;
    },
    // 获取人员列表
    async getSampledMemberList(){
      const api = this.$fetchApi.getSampledMemberList.api;
      const data = await this.$fetchData.fetchPost(this.memberParams, api, 'json');
      if (data.code === '200'&& data.result) {
        this.memberList = data.result.list;
      }
    },
    /** 复核人输入搜索 */
    querySearch(queryString, cb) {
      if (!queryString) {
        this.params.reviewUser = '';
      }
      const restaurants = this.memberList;
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        if (restaurant.realName == ''||restaurant.realName == null) return;
        return (restaurant.realName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    /** 复核人选择 */
    handleSelect(item) {
      this.memberParams.searchValue = item.realName;
      this.params.reviewUser = item.userId;
      this.params.reviewUserName = item.realName;
    }
  }
};
</script>

<style lang="less" scoped>
.applyForReview{
  margin-top: 20px;
  /deep/.avatar-uploader{
    width: 100%;
  }
  .tipText{
    color: #959DB3;
    font-size: 12px;
    margin-left: 10px;
  }
  .inputText0{
    width: 200px;
  }
  .inputText{
    width: 400px;
  }
  .inputTextarea{
    width: 400px;
    height: 75px;
  }
  .protBtn{
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 40px;
    .sub-btn{
      width: 150px;
      line-height: 35px;
      color: #ffffff;
      background-color:#3387FD;
      border-radius: 5px;
      text-align: center;
    }
    /deep/ .el-form-item__content{
      margin-left: 20px!important;
    }
  }
  .protBtn{
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 40px;
    .sub-btn{
      width: 150px;
      line-height: 35px;
      color: #ffffff;
      background-color:#3387FD;
      border-radius: 5px;
      text-align: center;
    }
    /deep/ .el-form-item__content{
      margin-left: 20px!important;
    }
  }
}
</style>